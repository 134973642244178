<template >
    <div>
        <div class="modal-header">
            <div class="modal-title-my">
                {{
                    $t("message.new_c",)
                }}
            </div>
            <div>
                <crm-store-update-close
                    :button_type="'store'"
                    :loading="loadingButton"
                    @c-submit="submit(true)"
                    @c-close="close()"
                ></crm-store-update-close>
            </div>
        </div>
        <div class="bodal-body-my">
            <div class="card-body p30">
                <div class="steps">
                    <ul class="steps-ul">
                        <li
                            v-for="(item, itemIndex) in steps"
                            :key="'steps-' + itemIndex"
                            :class="{ active: item.step === currentStep }"
                            @click="setStep(item.step)"
                        >
                            {{ item.stepText }}
                        </li>
                    </ul>
                </div>

                <div class="steps-body">
                    <el-form
                        ref="form"
                        :rules="rules"
                        :model="form"
                        class="my-form"
                        enctype="multipart/form-data"
                    >
                        <div v-if="currentStep === 1" class="step-itme-1">
                            <el-row :gutter="20" class="mb20">
                                <!-- <el-col :span="10">
                                    <el-form-item
                                        label="Klinikalar"
                                        class="icons-input"
                                        prop="clinic_id"
                                    >
                                        <el-select
                                            v-model="form.clinic_id"
                                            @change="selectedClinicId"
                                            placeholder=" Klinika "
                                            filterable
                                            clearable
                                        >
                                            <el-option
                                                v-for="(
                                                    clinic, index
                                                ) in clinics"
                                                :key="'clinic-' + index"
                                                :label="
                                                    clinic.brand_name +
                                                    ' / ' +
                                                    clinic.legal_entity_name
                                                "
                                                :value="clinic.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col> -->
                                <el-col :span="10">
                                    <el-form-item
                                        label="Filiallar"
                                        class="icons-input"
                                    >
                                        <el-select
                                            v-model="form.filial_id"
                                            placeholder="Filial"
                                            clearable
                                            filterable
                                        >
                                            <el-option
                                                v-for="(
                                                    filial, index
                                                ) in filtered_filial"
                                                :key="'filial-' + index"
                                                :label="
                                                    filial.filial_name +
                                                    ' / ' +
                                                    filial.brand_name
                                                "
                                                :value="filial.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-divider content-position="left">
                                    Tibbiy Texnikalar
                                </el-divider>

                                <el-col :span="8">
                                    <el-form-item
                                        prop="name"
                                        label="Texnika nomi"
                                    >
                                        <el-input
                                            v-model="form.name"
                                            placeholder="Texnika nomi"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Seriya va raqami">
                                        <el-input
                                            v-model="form.series_and_number"
                                            placeholder="Seriy va raqami"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Pasport">
                                        <el-select
                                            v-model="form.passport"
                                            placeholder="Pasport"
                                        >
                                            <el-option
                                                label="HA"
                                                :value="1"
                                            ></el-option>
                                            <el-option
                                                label="Yoq"
                                                :value="0"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item
                                        label="Ishlab chiqarilgan sanasi"
                                    >
                                        <el-date-picker
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            type="date"
                                            placeholder="Ishlab chiqarilgan sanasi"
                                            v-model="form.date_of_manufacture"
                                            style="width: 100%"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="Qo'llanmasi">
                                        <el-select
                                            v-model="form.manual"
                                            placeholder="Pasport"
                                            clearable
                                            filterable
                                        >
                                            <el-option
                                                label="HA"
                                                :value="1"
                                            ></el-option>
                                            <el-option
                                                label="Yoq"
                                                :value="0"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <div class="my-tabs-style">
                                <el-tabs type="border-card">
                                    <el-tab-pane label="Texnika fotosurati">
                                        <div class="imgUpload">
                                            <el-upload
                                                class="upload-demo"
                                                action="/"
                                                :limit="3"
                                                accept="image/*"
                                                name="image"
                                                multiple
                                                :file-list="technique_images"
                                                :auto-upload="false"
                                                :on-change="
                                                    updateTechniqueImage
                                                "
                                                :on-remove="
                                                    handleTechniqueImageRemove
                                                "
                                                list-type="text"
                                            >
                                                <el-button
                                                    size="small"
                                                    type="primary"
                                                    >Fotosuratni
                                                    joylang</el-button
                                                >
                                                <div
                                                    slot="tip"
                                                    class="el-upload__tip"
                                                >
                                                    jpg/png files with a size
                                                    less than 500kb
                                                </div>
                                            </el-upload>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane
                                        label="Seriya va raqami fotosurati"
                                    >
                                        <div class="imgUpload">
                                            <el-upload
                                                class="upload-demo"
                                                action="/"
                                                :limit="3"
                                                accept="image/*"
                                                name="image"
                                                multiple
                                                :file-list="
                                                    series_and_number_images
                                                "
                                                :auto-upload="false"
                                                :on-change="
                                                    updateSeriesAndNumberImage
                                                "
                                                :on-remove="
                                                    handleSeriesAndNumberImageRemove
                                                "
                                                list-type="text"
                                            >
                                                <el-button
                                                    size="small"
                                                    type="primary"
                                                    >Fotosuratni
                                                    joylang</el-button
                                                >
                                                <div
                                                    slot="tip"
                                                    class="el-upload__tip"
                                                >
                                                    jpg/png files with a size
                                                    less than 500kb
                                                </div>
                                            </el-upload>
                                        </div>
                                    </el-tab-pane>
                                    <el-tab-pane label="Pasporti fotosurati">
                                        <div class="imgUpload">
                                            <el-upload
                                                class="upload-demo"
                                                action="/"
                                                :limit="3"
                                                accept="image/*"
                                                name="image"
                                                multiple
                                                :file-list="passport_images"
                                                :auto-upload="false"
                                                :on-change="updatePassportImage"
                                                :on-remove="
                                                    handlePassportImageRemove
                                                "
                                                list-type="text"
                                            >
                                                <el-button
                                                    size="small"
                                                    type="primary"
                                                    >Fotosuratni
                                                    joylang</el-button
                                                >
                                                <div
                                                    slot="tip"
                                                    class="el-upload__tip"
                                                >
                                                    jpg/png files with a size
                                                    less than 500kb
                                                </div>
                                            </el-upload>
                                        </div>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>

                            <div class="step-btn">
                                <el-button
                                    class="asosy-btn-d"
                                    @click="nextStep"
                                    type="primary"
                                    icon="el-icon-right"
                                >
                                    Keyingi
                                </el-button>
                            </div>

                        </div>

                        <div v-if="currentStep === 2" class="step-itme-2">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="Guvohnomasi">
                                        <el-select
                                            v-model="form.document"
                                            placeholder="Guvohnomasi"
                                            @change="changeDocument"
                                        >
                                            <el-option
                                                label="HA"
                                                :value="1"
                                            ></el-option>
                                            <el-option
                                                label="Yoq"
                                                :value="0"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8" v-show="isDocument">
                                    <el-form-item label="Guvohnomasi va raqami">
                                        <el-input
                                            v-model="form.document_number"
                                            placeholder="Guvohnomasi va raqami"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8" v-show="isDocument">
                                    <el-form-item label="Guvohnomasi sanasi">
                                        <el-date-picker
                                            type="date"
                                            placeholder="Guvohnomasi sanasi"
                                            v-model="form.document_date"
                                            style="width: 100%"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-divider content-position="left">
                                    Standartlashtirishdan qachon o'tgan
                                    sertifikati
                                </el-divider>
                                <el-col :span="8">
                                    <el-form-item label="Sertifikati">
                                        <el-select
                                            v-model="form.certificate"
                                            placeholder="Sertifikati"
                                            @change="changeCertificate"
                                        >
                                            <el-option
                                                label="HA"
                                                :value="1"
                                            ></el-option>
                                            <el-option
                                                label="Yoq"
                                                :value="0"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-show="isCertificate">
                                    <el-form-item label="Sertifikati va raqami">
                                        <el-input
                                            v-model="form.certificate_number"
                                            placeholder="sertifikati va raqami"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8" v-show="isCertificate">
                                    <el-form-item label="Sertifikati sanasi">
                                        <el-date-picker
                                            type="date"
                                            format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd"
                                            placeholder="Sertifikati sanasi"
                                            v-model="form.certificate_date"
                                            style="width: 100%"
                                        ></el-date-picker>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <div class="imgUpload">
                                        <el-upload
                                            class="upload-demo"
                                            action="/"
                                            :limit="3"
                                            accept="image/*"
                                            name="image"
                                            multiple
                                            :with-credentials="true"
                                            :auto-upload="false"
                                            :on-change="updateCertificateImage"
                                            :on-remove="
                                                handleCertificateImageRemove
                                            "
                                            list-type="text"
                                            :file-list="certificate_images"
                                        >
                                            <el-button
                                                size="small"
                                                type="primary"
                                                >Fotosuratni joylang</el-button
                                            >
                                            <div
                                                slot="tip"
                                                class="el-upload__tip"
                                            >
                                                jpg/png files with a size less
                                                than 500kb
                                            </div>
                                        </el-upload>
                                    </div>
                                </el-col>
                            </el-row>

                            <el-divider content-position="left">
                                Texnikaning boshka sertifikatlari
                            </el-divider>

                            <el-row
                                :gutter="20"
                                v-for="(another, index) in another_certificates"
                                :key="'another-' + index"
                            >
                                <el-col :span="8">
                                    <el-form-item label="Boshka sertifikatlari">
                                        <el-input
                                            v-model="another.another_name"
                                            placeholder="Boshka sertifikatlari"
                                        ></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="24">
                                    <div class="imgUpload">
                                        <el-upload
                                            class="upload-demo"
                                            action="/"
                                            :limit="3"
                                            accept="image/*"
                                            name="image"
                                            multiple
                                            :file-list="another.images"
                                            :auto-upload="false"
                                            @input.native="
                                                getItemPosition(another.id)
                                            "
                                            :on-change="
                                                updateAnotherCertificateImage
                                            "
                                            :on-remove="
                                                handleAnotherCertificateImageRemove
                                            "
                                            list-type="text"
                                        >
                                            <el-button
                                                size="small"
                                                type="primary"
                                                >Fotosuratni joylang</el-button
                                            >
                                            <div
                                                slot="tip"
                                                class="el-upload__tip"
                                            >
                                                jpg/png files with a size less
                                                than 500kb
                                            </div>
                                        </el-upload>
                                    </div>
                                </el-col>
                                <i
                                    class="delet-icon el-icon-circle-close"
                                    @click="deleteAnotherCertificate(index)"
                                ></i>
                            </el-row>

                            <div class="w-100">
                                <div class="add-form-educet">
                                    <el-button
                                        class="asosy-btn-d"
                                        @click="addAnotherCertificate"
                                        icon="el-icon-plus"
                                    >
                                        Qo'shish
                                    </el-button>
                                </div>
                            </div>

                            <div class="step-btn">
                                <el-button
                                    class="asosy-btn-y"
                                    @click="prevStep"
                                    icon="el-icon-back"
                                >
                                    Oldingi
                                </el-button>
                                <el-button
                                    class="asosy-btn-d"
                                    @click="nextStep"
                                    type="primary"
                                    icon="el-icon-right"
                                >
                                    Keyingi
                                </el-button>
                            </div>
                        </div>

                        <div v-if="currentStep === 3" class="step-itme-3">
                            <el-row :gutter="20">
                                <el-divider content-position="left">
                                    Mulk huquqi
                                </el-divider>
                                <el-col :span="24">
                                    <el-form-item label=" Mulk huquqi">
                                        <select-property-ownerships
                                            :id="form.property_ownership_id"
                                            v-model="form.property_ownership_id"
                                            :size="'medium'"
                                        >
                                        </select-property-ownerships>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="24">
                                    <div class="imgUpload">
                                        <el-upload
                                            class="upload-demo"
                                            action="/"
                                            :limit="3"
                                            accept="image/*"
                                            name="image"
                                            multiple
                                            :auto-upload="false"
                                            :on-change="
                                                updatePropertyOwnershipImage
                                            "
                                            :on-remove="
                                                handlePropertyOwnershipRemove
                                            "
                                            list-type="text"
                                            :file-list="
                                                property_ownership_images
                                            "
                                        >
                                            <el-button
                                                size="small"
                                                type="primary"
                                                >Fotosuratni joylang</el-button
                                            >
                                            <div
                                                slot="tip"
                                                class="el-upload__tip"
                                            >
                                                jpg/png files with a size less
                                                than 500kb
                                            </div>
                                        </el-upload>
                                    </div>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20" class="mt-20">
                                <el-divider content-position="left">
                                    Texnikaning Holati Darajasi
                                </el-divider>
                                <el-col :span="8">
                                    <el-form-item label="Muddati">
                                        <select-conditions
                                            :id="form.condition_id"
                                            v-model="form.condition_id"
                                            :size="'medium'"
                                        >
                                        </select-conditions>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item
                                        label="Qancha vaqt foydalanish mumkin"
                                    >
                                        <el-input-number
                                            v-model="form.how_long_use_it"
                                            :min="0"
                                            placeholder="Qancha vaqt foydalanish mumkin"
                                        ></el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <div class="step-btn">
                                <el-button
                                    class="asosy-btn-y"
                                    @click="prevStep"
                                    icon="el-icon-back"
                                >
                                    Oldingi
                                </el-button>
                                <el-button
                                    class="asosy-btn-d"
                                    @click="nextStep"
                                    type="primary"
                                    icon="el-icon-right"
                                >
                                    Keyingi
                                </el-button>
                            </div>
                        </div>
                    </el-form>
                </div>

              
            </div>
        </div>
        <!-- end app-modal__body -->
    </div>
</template>
<script>
import medicalTechnique from "@/utils/mixins/models/medicalTechnique";
import selectClinic from "@/components/filters/inventory/select-clinic.vue";
import SelectPropertyOwnerships from "@/components/filters/inventory/select-property-ownerships.vue";
import SelectConditions from "@/components/filters/inventory/select-conditions.vue";
import drawer from "@/utils/mixins/drawer";
import { mapActions } from "vuex";
export default {
    components: { selectClinic, SelectPropertyOwnerships, SelectConditions },
    mixins: [medicalTechnique, drawer],
    data() {
        return {};
    },
    computed: {},
    methods: {
        ...mapActions({
            save: "medicalTechniques/store",
        }),
        afterOpen() {
            this.form = JSON.parse(JSON.stringify(this.model));
            this.form.clinic_id = this.$route.params.id;
            this.technique_images = [];
            this.series_and_number_images = [];
            this.passport_images = [];
            this.property_ownership_images = [];
            this.certificate_images = [];
            this.another_certificates = [
                {
                    id: 1,
                    another_name: "",
                    images: [],
                },
            ];
            if (this.$route.params.id) {
                let query = {clinic_id: this.$route.params.id};
                this.getFilteredFilials(query);
            }else{
                this.$store.commit('clinics/EMPTY_FILIAL');
            }
        },
    },
};
</script>
<style lang="scss">
.delet-icon {
    font-size: 30px;
    position: absolute;
    right: -24px;
    top: 35px;
    color: red;
}
</style>
